import { PassengerTypeDiscountFilter } from 'dto/passengerTypeDiscount';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { Grid, Stack } from '@mui/material';
import {
  getPassengerTypeDiscounts,
  setPassengerTypeDiscountsFilter,
} from 'features/passengerTypeDiscount/passengerTypeDiscountActions';
import {
  AddButton,
  Button,
  decimalToPercentage,
  FormControl,
  FormProvider,
  formSubmit,
  percentageToDecimal,
  SearchForm,
  useForm,
} from '@fleet/shared';
import { TransTitle } from 'i18n/trans/title';
import { Link } from 'react-router-dom';
import { TransButton } from 'i18n/trans/button';
import { PassengerTypeDiscountsFields } from 'routes/PassengerTypeDiscounts/PassengerTypeDiscountsFields';
import { useDispatch, useSelector } from 'store/utils';
import { passengerTypeDiscountsFilterSelector } from 'features/passengerTypeDiscount/passengerTypeDiscountsSelector';

interface PassengerTypeDiscountsSearchFormProps {}

export const PassengerTypeDiscountsSearchForm: FC<PassengerTypeDiscountsSearchFormProps> =
  () => {
    const dispatch = useDispatch();
    const filter = useSelector(passengerTypeDiscountsFilterSelector);
    const currentBusinessEntityId = useSelector(
      currentBusinessEntityIdSelector
    );
    const initialValues = useMemo(
      () => ({
        ownerId: currentBusinessEntityId,
        ...(filter && {
          ...filter,
          discountPercentageAmount:
            filter.discountPercentageAmount &&
            decimalToPercentage(filter.discountPercentageAmount),
        }),
      }),
      [currentBusinessEntityId, filter]
    );

    useEffect(() => {
      if (Object.keys(filter).length === 0) {
        dispatch(setPassengerTypeDiscountsFilter(initialValues));
      }
    }, [dispatch, filter, initialValues]);

    const onSubmit = useCallback(
      ({
        discountPercentageAmount,
        ...rest
      }: Partial<PassengerTypeDiscountFilter>) => {
        formSubmit(async () => {
          (document.activeElement as HTMLInputElement)?.blur?.();
          await dispatch(
            getPassengerTypeDiscounts({
              ...rest,
              ...(discountPercentageAmount && {
                discountPercentageAmount: percentageToDecimal(
                  discountPercentageAmount
                ),
              }),
              offset: 0,
            })
          );
        });
      },
      [dispatch]
    );
    const { form, handleSubmit } = useForm({ initialValues, onSubmit });

    const handleReset = useCallback(() => {
      form.reset();
      dispatch(setPassengerTypeDiscountsFilter({}));
    }, [dispatch, form]);

    return (
      <SearchForm
        title={<TransTitle i18nKey="passengerTypeDiscounts" />}
        action={
          <AddButton component={Link} to="/passenger-type-discounts/create">
            <TransButton i18nKey="add" />
          </AddButton>
        }
      >
        <FormProvider form={form}>
          <form onSubmit={handleSubmit}>
            <Grid container columns={4} spacing={2}>
              <PassengerTypeDiscountsFields mode="search" />
              <Grid item xs="auto" sx={{ ml: 'auto' }}>
                <Stack direction="row" spacing={2}>
                  <FormControl label="&nbsp;">
                    <Button
                      sx={{ whiteSpace: 'nowrap' }}
                      variant="text"
                      onClick={handleReset}
                    >
                      <TransButton i18nKey="resetFilters" />
                    </Button>
                  </FormControl>
                  <FormControl label="&nbsp;">
                    <Button variant="contained" type="submit" icon="search">
                      <TransButton i18nKey="search" />
                    </Button>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </SearchForm>
    );
  };
