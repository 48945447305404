import React, { FC, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'store/utils';
import {
  getFareModel,
  setFareModel,
} from 'features/fareModel/fareModelActions';
import { fareModelFixedLoadingSelector } from 'features/loading/loadingSelectors';
import { Button, CardHeader, Icon, Layout, Loadable } from '@fleet/shared';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransButton } from 'i18n/trans/button';
import { fareModelSelector } from 'features/fareModel/fareModelSelectors';
import { FareModelFixedTable } from 'routes/FareModels/FareModelFixed/FareModelFixedTable';
import { getFixedFares } from 'features/fareModel/fareModelFixedActions';

export const FareModelFixed: FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const loading = useSelector(fareModelFixedLoadingSelector);
  const fareModel = useSelector(fareModelSelector);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      dispatch(setFareModel());
      await dispatch(getFareModel(id)).unwrap();
      await dispatch(getFixedFares()).unwrap();
    })();
  }, [dispatch, id]);

  return (
    <Loadable loading={loading}>
      <Layout
        header={
          <CardHeader
            title={
              <TransSubtitle
                i18nKey="fixedFares"
                values={{ name: fareModel?.name }}
              />
            }
          >
            <Button
              variant="text"
              startIcon={<Icon name="arrow-left-circle" />}
              onClick={() => history.push(`/fare-models/edit/${fareModel?.id}`)}
              color="yellow"
            >
              <TransButton i18nKey="back" />
            </Button>
          </CardHeader>
        }
      >
        <FareModelFixedTable />
      </Layout>
    </Loadable>
  );
};
