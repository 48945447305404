import { FC, MouseEvent, useCallback, useEffect, useMemo } from 'react';
import { Row, usePagination, useRowSelect, useTable } from 'react-table';
import { Divider, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { Table, TableColumns } from '@fleet/shared/components/Table';
import { useRowSelectCheckbox } from '@fleet/shared/hooks';
import { TransTableHead } from 'i18n/trans/table';
import { PromotionalDiscountsSearchForm } from 'routes/PromotionalDiscounts/PromotionalDiscountsSearchForm';
import { useDispatch, useSelector } from 'store/utils';
import {
  promotionalDiscountsFilterSelector,
  promotionalDiscountsSelector,
} from 'features/promotionalDiscount/promotionalDiscountSelector';
import { PromotionalDiscount } from 'dto/promotionalDiscount';
import { businessEntitiesSelector } from 'features/classification/classificationSelectors';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import {
  deletePromotionalDiscount,
  getPromotionalDiscounts,
  setPromotionalDiscount,
} from 'features/promotionalDiscount/promotionalDiscountActions';
import { currentDateTimeFormat, formatDate } from '@fleet/shared/utils/date';
import {
  Icon,
  Loadable,
  SearchResult,
  tableRowClickEventWrapper,
  decimalToPercentage,
} from '@fleet/shared';
import { promotionalDiscountsTableLoadingSelector } from 'features/loading/loadingSelectors';
import { useHistory, useParams } from 'react-router-dom';
import { TransAlert } from 'i18n/trans/alert';
import { useAlert } from 'react-alert';

const IS_DELETE_FUNCTIONALITY_HIDDEN = true; //This functionality is't fully implemented in the BE yet

export interface PromotionalDiscountsTableProps {}

export const PromotionalDiscountsTable: FC<PromotionalDiscountsTableProps> =
  () => {
    const discounts = useSelector(promotionalDiscountsSelector);
    const data = useMemo(() => discounts?.items ?? [], [discounts?.items]);
    const loading = useSelector(promotionalDiscountsTableLoadingSelector);
    const businessEntities = useSelector(businessEntitiesSelector);
    const dispatch = useDispatch();
    const history = useHistory();
    const alert = useAlert();
    const { id } = useParams<{ id?: string }>();

    useEffect(() => {
      if (!id) {
        dispatch(setPromotionalDiscount());
      }
    }, [id, dispatch]);

    const handleRowRemove = useCallback(
      async (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        const discountId = event.currentTarget.id;
        await dispatch(deletePromotionalDiscount(discountId));
        alert.success(<TransAlert i18nKey="promotionalDiscountDeleted" />);
      },
      [alert, dispatch]
    );

    const deleteColumn = useMemo<TableColumns<PromotionalDiscount>>(
      () => [
        {
          accessor: 'id',
          Cell: ({ value }) => (
            <IconButton
              id={`${value}`}
              onClick={handleRowRemove}
              color="inherit"
            >
              <Icon name="delete" />
            </IconButton>
          ),
          width: 0,
        },
      ],
      [handleRowRemove]
    );

    const link = useCallback(
      (row: Row<PromotionalDiscount>) =>
        `/promotional-discounts/edit/${row.original.id}`,
      []
    );

    const columns: TableColumns<PromotionalDiscount> = useMemo(() => {
      return [
        {
          accessor: 'name',
          Header: <TransTableHead i18nKey="discountName" />,
          Cell: ({ row, value }) => (
            <Link to={link(row)} onClick={tableRowClickEventWrapper}>
              {value}
            </Link>
          ),
        },
        {
          accessor: 'ownerId',
          Cell: ({ value }) =>
            businessEntities.find(({ id }) => id === value)?.name,
          Header: <TransTableHead i18nKey="owner" />,
        },
        {
          id: 'discountTypeName',
          accessor: ({ discountType }) => discountType.name,
          Header: <TransTableHead i18nKey="discountCalculationType" />,
        },
        {
          accessor: 'discountPercentageAmount',
          Header: <TransTableHead i18nKey="discountWithPercentage" />,
          Cell: ({ value }) => (value ? decimalToPercentage(value) : ''),
        },
        {
          id: 'currencyName',
          accessor: ({ currency }) => currency.name,
          Header: <TransTableHead i18nKey="currency" />,
        },
        {
          id: 'validFrom',
          accessor: 'validity',
          Header: () => <TransTableHead i18nKey="validFrom" />,
          Cell: ({ value: { from } }) =>
            from ? formatDate(from, currentDateTimeFormat) : '–',
        },
        {
          id: 'validTo',
          accessor: 'validity',
          Header: () => <TransTableHead i18nKey="validTo" />,
          Cell: ({ value: { to } }) =>
            to ? formatDate(to, currentDateTimeFormat) : '–',
        },
        ...(IS_DELETE_FUNCTIONALITY_HIDDEN ? [] : deleteColumn),
      ];
    }, [businessEntities, deleteColumn, link]);

    const getPage = useCallback(
      (pageSize: number) => {
        if (discounts) {
          const { limit = pageSize, offset } = discounts;
          return offset / limit;
        }
        return 0;
      },
      [discounts]
    );
    const filter = useSelector(promotionalDiscountsFilterSelector);

    const handlePageChange = useCallback(
      async (paginationParams: PaginationParams) =>
        await dispatch(
          getPromotionalDiscounts({ ...filter, ...paginationParams })
        ).unwrap(),
      [dispatch, filter]
    );

    const table = useTable<PromotionalDiscount>(
      {
        data,
        columns: columns,
        pageCount: -1,
        total: discounts?.totalCount,
        useControlledState: (state) => ({
          ...state,
          pageIndex: getPage(state.pageSize),
        }),
        manualPagination: true,
        onPageChange: handlePageChange,
      },
      usePagination,
      useRowSelect,
      useRowSelectCheckbox
    );

    return (
      <Loadable loading={loading}>
        <PromotionalDiscountsSearchForm />
        <Divider />
        <SearchResult results={data.length} loading={loading}>
          <Table
            table={table}
            getRowProps={(_, { row }) => ({
              sx: { cursor: 'pointer' },
              onClick: () => history.push(link(row)),
            })}
          />
        </SearchResult>
      </Loadable>
    );
  };
