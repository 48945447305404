import type { FC } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { Grid, Stack } from '@mui/material';
import { TransLabel } from 'i18n/trans/label';
import { TransButton } from 'i18n/trans/button';
import { TransNav } from 'i18n/trans/nav';
import {
  AddButton,
  Button,
  decimalToPercentage,
  FormProvider,
  formSubmit,
  percentageToDecimal,
  PeriodField,
  SearchForm,
  TextField,
  useForm,
} from '@fleet/shared';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'store/utils';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import type { PromotionalDiscountFilter } from 'dto/promotionalDiscount';
import { SelectOwnerField } from 'components/SelectOwnerField';
import { SelectCurrencyField } from 'components/SelectCurrencyField';
import { TransField } from 'i18n/trans/field';
import {
  getPromotionalDiscounts,
  setPromotionalDiscountFilter,
} from 'features/promotionalDiscount/promotionalDiscountActions';
import { promotionalDiscountsFilterSelector } from 'features/promotionalDiscount/promotionalDiscountSelector';

export const PromotionalDiscountsSearchForm: FC = () => {
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);
  const filter = useSelector(promotionalDiscountsFilterSelector);
  const dispatch = useDispatch();

  const initialValues = useMemo(
    () => ({
      ownerId: currentBusinessEntityId,
      ...(filter && {
        ...filter,
        discountPercentageAmount: filter.discountPercentageAmount
          ? decimalToPercentage(filter.discountPercentageAmount)
          : null,
      }),
    }),
    [currentBusinessEntityId, filter]
  );

  useEffect(() => {
    if (Object.keys(filter).length === 0) {
      dispatch(setPromotionalDiscountFilter(initialValues));
    }
  }, [dispatch, filter, initialValues]);

  const onSubmit = useCallback(
    ({ discountPercentageAmount, ...rest }: PromotionalDiscountFilter) =>
      formSubmit(
        async () =>
          await dispatch(
            getPromotionalDiscounts({
              ...rest,
              discountPercentageAmount: discountPercentageAmount
                ? percentageToDecimal(discountPercentageAmount)
                : null,
              offset: 0,
            })
          )
      ),
    [dispatch]
  );

  const { form, handleSubmit } = useForm<PromotionalDiscountFilter>({
    initialValues,
    onSubmit,
  });

  const handleReset = useCallback(() => {
    form.reset();
    dispatch(setPromotionalDiscountFilter({}));
  }, [dispatch, form]);

  return (
    <SearchForm
      title={<TransNav i18nKey="promotionalDiscounts" />}
      action={
        <AddButton component={Link} to="/promotional-discounts/create">
          <TransButton i18nKey="add" />
        </AddButton>
      }
    >
      <FormProvider form={form}>
        <form onSubmit={handleSubmit}>
          <Grid container columns={5} spacing={2}>
            <Grid item xs={1}>
              <SelectOwnerField required />
            </Grid>
            <Grid item xs={1}>
              <TextField
                label={<TransLabel i18nKey="discountName" />}
                name="name"
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                label={<TransLabel i18nKey="discountWithPercentage" />}
                name="discountPercentageAmount"
                type="number"
              />
            </Grid>
            <Grid item xs={1}>
              <SelectCurrencyField />
            </Grid>
            <PeriodField
              from={{
                name: 'validityFrom.from',
                label: <TransField i18nKey="validFromRange" />,
                isClearable: true,
              }}
              to={{
                name: 'validityFrom.to',
                isClearable: true,
              }}
            />
            <PeriodField
              from={{
                name: 'validityTo.from',
                label: <TransField i18nKey="validToRange" />,
                isClearable: true,
              }}
              to={{
                name: 'validityTo.to',
                isClearable: true,
              }}
            />
            <Stack
              direction="row"
              sx={{ ml: 'auto', mt: 4, alignItems: 'end' }}
              spacing={1}
            >
              <Button onClick={handleReset} variant="text">
                <TransButton i18nKey="reset" />
              </Button>
              <Button icon="search" type="submit" sx={{ ml: 2 }}>
                <TransButton i18nKey="search" />
              </Button>
            </Stack>
          </Grid>
        </form>
      </FormProvider>
    </SearchForm>
  );
};
