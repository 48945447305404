import {
  Icon,
  SearchResult,
  Table,
  TableColumns,
  tableRowClickEventWrapper,
  useRowActive,
  useTableRowHighlight,
} from '@fleet/shared';
import { FC, useCallback, useMemo } from 'react';
import { PassengerTypeSearchForm } from './PassengerTypeSearchForm';
import { Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'store/utils';
import {
  passengerTypeFilterSelector,
  passengerTypesSelector,
} from 'features/passengerType/passengerTypeSelectors';
import { Row, usePagination, useTable } from 'react-table';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { getPassengerTypes } from 'features/passengerType/passengerTypeActions';
import { PassengerType } from 'dto/passengerType';
import { TransTableHead } from 'i18n/trans/table';
import { useHistory, useParams } from 'react-router-dom';
import { passengerTypesLoadingSelector } from 'features/loading/loadingSelectors';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';

export const PassengerTypeTable: FC = () => {
  const { id } = useParams<{ id?: string }>();
  const passengerTypes = useSelector(passengerTypesSelector);
  const data = useMemo(() => passengerTypes?.items ?? [], [passengerTypes]);
  const dispatch = useDispatch();
  const history = useHistory();
  const filter = useSelector(passengerTypeFilterSelector);
  const loading = useSelector(passengerTypesLoadingSelector);
  const passengerTypeCategoryOptions = useClassificationOptions(
    ClassificationGroup.PASSENGER_TYPE_CATEGORY
  );
  const businessEntityOptions = useClassificationOptions(
    ClassificationGroup.BUSINESS_ENTITY
  );

  const link = useCallback(
    (row: Row<PassengerType>) => `/passenger-types/edit/${row.original.id}`,
    []
  );

  const columns = useMemo<TableColumns<PassengerType>>(
    () => [
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="name" />,
        Cell: ({ row, value }) => (
          <Link to={link(row)} onClick={tableRowClickEventWrapper}>
            {value}
          </Link>
        ),
      },
      {
        accessor: 'ownerId',
        Header: <TransTableHead i18nKey="owner" />,
        Cell: ({ value }) =>
          businessEntityOptions.find(
            (businessEntity) => businessEntity.value === value
          )?.label ?? '',
      },
      {
        accessor: 'passengerTypeCategoryId',
        Header: <TransTableHead i18nKey="category" />,
        Cell: ({ value }) =>
          passengerTypeCategoryOptions.find(
            (passengerType) => passengerType.value === value
          )?.label,
      },
      {
        accessor: 'isActive',
        Header: <TransTableHead i18nKey="isActive" />,
        Cell: ({ value }) =>
          value ? (
            <Icon name="check" color="success" />
          ) : (
            <Icon name="close" color="error" />
          ),
      },
      {
        accessor: 'isWithoutSeatReservation',
        Header: <TransTableHead i18nKey="seatReservationNotAllowed" />,
        Cell: ({ value }) =>
          value ? (
            <Icon name="check" color="success" />
          ) : (
            <Icon name="close" color="error" />
          ),
        width: 175,
      },
    ],
    [businessEntityOptions, link, passengerTypeCategoryOptions]
  );

  const getPage = useCallback(
    (pageSize: number) => {
      if (passengerTypes) {
        const { limit = pageSize, offset } = passengerTypes;
        return offset / limit;
      }
      return 0;
    },
    [passengerTypes]
  );

  const getRowId = useCallback((row: PassengerType) => row.id.toString(), []);

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) =>
      await dispatch(
        getPassengerTypes({ ...filter, ...paginationParams })
      ).unwrap(),
    [dispatch, filter]
  );

  const table = useTable<PassengerType>(
    {
      data,
      columns,
      pageCount: -1,
      total: passengerTypes?.totalCount,
      useControlledState: (state) => ({
        ...state,
        pageIndex: getPage(state.pageSize),
      }),
      manualPagination: true,
      onPageChange: handlePageChange,
      getRowId,
    },
    usePagination,
    useRowActive
  );

  useTableRowHighlight(id, table);

  return (
    <>
      <PassengerTypeSearchForm />
      <Divider />
      <SearchResult results={!!data.length} loading={loading}>
        <Table
          table={table}
          getRowProps={(_, { row }) => ({
            sx: { cursor: 'pointer' },
            onClick: () => history.push(link(row)),
          })}
        />
      </SearchResult>
    </>
  );
};
