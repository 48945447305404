import { FC, useCallback, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import {
  ConfirmDeleteModal,
  FormProvider,
  Table,
  TableColumns,
  api,
  useForm,
  useFormTable,
  useFormTableControls,
  useIndeterminateRowSelectCheckbox,
  useModal,
} from '@fleet/shared';
import { FareModelDistanceRangesFilterForm } from 'routes/FareModels/FareModelDistances/FareModelDistanceRangesFilterForm';
import { Row, useExpanded, usePagination, useRowSelect } from 'react-table';
import { useDispatch, useSelector } from 'store/utils';
import { fareModelSelector } from 'features/fareModel/fareModelSelectors';
import { Button, CardHeader, Divider } from '@mui/material';
import { Icon } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import { useRowEditActions } from '@fleet/shared/hooks';
import { TransTableHead } from 'i18n/trans/table';
import { FareModelDistanceRangeFaresTable } from 'routes/FareModels/FareModelDistances/FareModelDistanceRangeFaresTable';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { TransModal } from 'i18n/trans/modal';
import { getFareModelDistanceFares } from 'features/fareModel/fareModelDistanceActions';
import { FareModelDistanceInterval } from 'dto/fareModelDistance';
import {
  fareModelDistancesFaresSelector,
  selectFareModelDistanceFaresFilter,
} from 'features/fareModel/fareModelDistanceSelectors';
import { noop } from '@fleet/shared/utils/noop';
import { PaginationParams } from '@fleet/shared/dto/pagination';

const useStyles = makeStyles(
  () => ({
    root: {
      overflow: 'auto',
      height: '100%',
    },
  }),
  {
    name: 'FareModelDistanceRangesTable',
  }
);

const mapUniq = <T,>(arr: Array<T>, join = true) => {
  const items = new Set(arr);
  return items.size
    ? items.size === 1
      ? [...items][0]
      : join
      ? [...items].join(',')
      : items.size
    : '一';
};

export const FareModelDistanceRangesTable: FC = () => {
  const dispatch = useDispatch();
  const modelDistancesFares = useSelector(fareModelDistancesFaresSelector);
  const data = useMemo(
    () => modelDistancesFares?.items ?? [],
    [modelDistancesFares?.items]
  );
  const currentFareModel = useSelector(fareModelSelector)!;
  const implementationMethodOptions = useClassificationOptions(
    ClassificationGroup.DISTANCE_FARE_IMPLEMENTATION_METHOD
  );
  const { open: isOpen, onOpen, onClose } = useModal();

  const columns = useMemo<TableColumns<FareModelDistanceInterval>>(
    () => [
      {
        id: 'expandToggle',
        width: 40,
        Cell: ({ row }: { row: Row<FareModelDistanceInterval> }) => {
          if (!row.original.id) {
            return <span />;
          }

          return (
            <Icon
              {...row.getToggleRowExpandedProps()}
              name={`chevron-${row.isExpanded ? 'down' : 'right'} `}
            />
          );
        },
      },
      {
        accessor: 'distanceFrom',
        Header: <TransTableHead i18nKey="distanceFrom" />,
      },
      {
        accessor: 'distanceTo',
        Header: <TransTableHead i18nKey="distanceTo" />,
      },
      {
        id: 'implementationMethod.id',
        accessor: ({ implementationMethod }) => implementationMethod?.id,
        Header: <TransTableHead i18nKey="method" />,
        type: 'select',
        editableProps: {
          options: implementationMethodOptions,
        },
      },
      {
        id: 'fares',
        Header: <TransTableHead i18nKey="fares" />,
        Cell: ({ row }: { row: Row<FareModelDistanceInterval> }) => (
          <strong>
            <TransTableHead
              i18nKey="faresQty"
              values={{ count: row.original.fares.length }}
              tOptions={{ postProcess: 'interval' }}
            />
          </strong>
        ),
      },
      {
        id: 'currencies',
        Header: <TransTableHead i18nKey="currencies" />,
        Cell: ({ row }: { row: Row<FareModelDistanceInterval> }) =>
          mapUniq(row.original.fares.map(({ currency }) => currency.name)),
      },
      {
        id: 'categories',
        Header: <TransTableHead i18nKey="categories" />,
        Cell: ({ row }: { row: Row<FareModelDistanceInterval> }) =>
          mapUniq(
            row.original.fares.map(({ fareCategory }) => fareCategory.name),
            false
          ),
      },
    ],
    [implementationMethodOptions]
  );

  const { form } = useForm<{ rows: Array<FareModelDistanceInterval> }>({
    initialValues: {
      rows: data,
    },
  });

  const handleRowUpdate = useCallback(
    async ({
      id,
      implementationMethod,
      ...rest
    }: FareModelDistanceInterval) => {
      await (id ? api.put : api.post)(
        `/fare-models/${currentFareModel.id}/distance-intervals${
          id ? `/${id}` : ''
        }`,
        { ...rest, implementationMethodId: implementationMethod.id }
      );

      dispatch(getFareModelDistanceFares());
    },
    [currentFareModel, dispatch]
  );

  const getPage = useCallback(
    (pageSize: number) => {
      if (modelDistancesFares) {
        const { limit = pageSize, offset } = modelDistancesFares;
        return offset / limit;
      }
      return 0;
    },
    [modelDistancesFares]
  );
  const filter = useSelector(selectFareModelDistanceFaresFilter);

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) =>
      await dispatch(
        getFareModelDistanceFares({ ...filter, ...paginationParams })
      ).unwrap(),
    [dispatch, filter]
  );

  const table = useFormTable<FareModelDistanceInterval>(
    {
      data,
      columns,
      form,
      autoResetExpanded: false,
      initialState: {
        selectedRowIds: {},
      },
      pageCount: -1,
      total: modelDistancesFares?.totalCount,
      useControlledState: (state) => ({
        ...state,
        pageIndex: getPage(state.pageSize),
      }),
      manualPagination: true,
      onPageChange: handlePageChange,
      onRowUpdate: handleRowUpdate,
      newRowDefaultValues: { fares: [] },
    },
    useExpanded,
    usePagination,
    useRowSelect,
    useIndeterminateRowSelectCheckbox,
    useRowEditActions
  );

  const onRowsRemoved = useCallback(
    async (rows: Array<FareModelDistanceInterval>) => {
      await Promise.all(
        rows.map((row) =>
          api.delete(
            `/fare-models/${currentFareModel.id}/distance-intervals/${row.id}`
          )
        )
      );

      onClose();
      dispatch(getFareModelDistanceFares());
    },
    [currentFareModel, dispatch, onClose]
  );

  const { addRow, removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: onRowsRemoved,
  });

  const classes = useStyles();
  return (
    <FormProvider form={form}>
      <Table
        classes={classes}
        table={table}
        caption={
          <>
            <FareModelDistanceRangesFilterForm />
            <Divider />
            <CardHeader
              sx={{ p: 2, pr: 1 }}
              action={
                <>
                  <Button
                    startIcon={<Icon name="trash" />}
                    onClick={onOpen}
                    disabled={!Object.keys(table.state.selectedRowIds).length}
                  >
                    <TransButton i18nKey="deleteSelected" />
                  </Button>
                  <ConfirmDeleteModal
                    handleDelete={removeSelectedRows}
                    title={<TransModal i18nKey="distanceFareDeletionTitle" />}
                    description={
                      <TransModal i18nKey="distanceFareDeletionDescription" />
                    }
                    isOpen={isOpen}
                    onClose={onClose}
                  />
                  <Button startIcon={<Icon name="plus" />} onClick={addRow}>
                    <TransButton i18nKey="addNew" />
                  </Button>
                </>
              }
            />
          </>
        }
        getTableProps={() => ({ sx: { tableLayout: 'fixed', p: 1 } })}
        getRowProps={() => ({
          sx: { backgroundColor: 'common.white' },
          onClick: noop,
        })}
        getSubRow={(row) => (
          <FareModelDistanceRangeFaresTable
            distanceIntervalId={row.original.id}
            fares={row.original.fares}
          />
        )}
      />
    </FormProvider>
  );
};
